<template>
  <div class="merchant-container">
    <div style="height: 50px">
      <div style="float: left; margin: 5px 0 0 20px">
        <el-button style="margin-right: 10px" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加
        </el-button>
      </div>
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="秒杀段名称:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="秒杀段名称"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-table
        :data="list"
        size="small"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="编号"
          width="80"
      />
      <el-table-column
          align="center"
          prop="name"
          label="秒杀时段名称"
      />
      <el-table-column
          align="center"
          prop="begin_date"
          label="每日开始时间"
      />
      <el-table-column
          align="center"
          label="每日结束时间"
          prop="end_date"
      />
      <el-table-column
          align="center"
          label="是否启用"
          prop="state"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <span v-if="row.state == 1">启用</span>
          <span v-else>禁用</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </div>
</template>
<script>
import Edit from './components/fieldSetting_Edit'
import {apiDeleteSession, apiGetSessionList} from "@/request/api";

export default {
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        type: 1,
      },
      list: [],
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteSession({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetSessionList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
