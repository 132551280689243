<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="570px"
      @close="close"
  >
    <el-form ref="form" label-width="120px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label='秒杀时段名称' prop="name">
        <el-input v-model="form.name" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label='每日开始时间' prop="begin_date">
        <el-time-picker
            v-model="form.begin_date"
            value-format="HH:mm:ss"
            placeholder="任意时间点"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label='每日结束时间' prop="end_date">
        <el-time-picker
            v-model="form.end_date"
            value-format="HH:mm:ss"
            placeholder="任意时间点"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否启用" prop="state">
        <el-switch
            v-model="form.state"
            active-color="#13ce66"
            active-text="开启"
            :active-value="1"
            inactive-color="#ff4949"
            inactive-text="禁用"
            :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {formatDate} from '@/utils/theTimeStamp'
import {apiUpdateSession} from "@/request/api";

export default {
  name: 'Edit',
  data() {
    return {
      form: {
        id: '',
        name: '',
        begin_date: '',
        end_date: '',
        state: 1,
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '请输入秒杀时段名称'},
        ],
        begin_date: [
          {required: true, trigger: 'blur', message: '请输入每日开始时间'},
        ],
        end_time: [
          {required: true, trigger: 'blur', message: '请输入每日结束时间'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        const tempObj = Object.assign({}, row)
        this.form = {
          id: tempObj.id,
          name: tempObj.name,
          begin_date: tempObj.begin_date,
          end_date: tempObj.end_date,
          state: parseInt(tempObj.state),
        }
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdateSession(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(res.message)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
